<template>
  <div class="opport">
    <!-- <div class="navigation">
      <span class="navigationLeft">News Detail</span>
    </div>
    <div class="newsDetail">
      <div class="title">{{ title }}</div>
      <div class="subTitle">
        <div class="subTitleLeft">
          <span style="margin-right: 2.08333vw">
            {{ $moment(data.publishTime).format("YYYY-MM-DD") }}
          </span>
        </div>
        <span
          class="subTitleRight"
          v-if="list[curIndex + 1]"
          @click="toDetail(list[curIndex + 1])"
        >
          下一篇<img src="../assets/img/second/iconJT.png" alt="" />
        </span>
      </div>
      <div class="content" v-html="content"></div>
      <div class="bottomNew">
        <div
          class="curpointer ellipsis"
          v-if="list[curIndex - 1]"
          @click="toDetail(list[curIndex - 1])"
        >
          上一篇: {{ list[curIndex - 1].title }}
        </div>
        <div
          class="curpointer ellipsis"
          v-if="list[curIndex + 1]"
          @click="toDetail(list[curIndex + 1])"
        >
          下一篇: {{ list[curIndex + 1].title }}
        </div>
      </div>
    </div> -->
    <div class="card" v-if="!isHideTop && college !=='d9cdydbdh'">
<!--      <div class="title" v-html="title"></div>-->
      <img src="../assets/img/second/detailBg.jpg" class="opportbg" />
    </div>
    <div class="main" :style="{'margin-top': !isHideTop || hide ? '0':'3.125vw'}">
      <div class="content">
        <TextDetail :content="data" class="content-page"/>
      </div>
<!--      <div class="some" v-if="type !== 'list'">-->
<!--        <div class="xgyd">-->
<!--          <div class="title">-->
<!--            <div class="line"></div>-->
<!--            <div>相关阅读</div>-->
<!--          </div>-->
<!--          <div-->
<!--            class="item"-->
<!--            v-for="(item, index) in list.slice(0, 6)"-->
<!--            :key="index"-->
<!--            @click="toDetail(item)"-->
<!--          >-->
<!--            <div class="left">-->
<!--              <div class="day">-->
<!--                {{ $moment(item.publishTime).format("DD") }}-->
<!--              </div>-->
<!--              <div class="year">-->
<!--                {{ $moment(item.publishTime).format("YYYY-MM") }}-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="right">-->
<!--              <div class="ellipsis2">{{ item.title }}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="page" v-if="type !== 'list'">
      <div class="list">
        <div class="item" style="margin-bottom: 1.04167vw;padding-top: 2.08333vw;" v-if="lastPage"  @click="toDetail(list[curIndex - 1])">
          <div class="ellipsis1">上一篇：{{ lastPage.title }}</div>
        </div>
        <div class="item" v-if="afterPage" style="padding-bottom: 2.08333vw;"  @click="toDetail(list[curIndex + 1])">
          <div class="ellipsis1">下一篇：{{ afterPage.title }}</div>
        </div>
      </div>
      <div class="btn" @click="toList()">返回列表</div>
    </div>
  </div>
</template>

<script>
import TextDetail from "../components/WgTextDetail.vue";
export default {
  name: "newsDetail",
  components: { TextDetail },
  data() {
    return {
      data: {},
      content: "",
      title: "",
      columnId: "",
      curIndex: null,
      list: [],
      type: "",
      isHideTop: false,
      hide: false,
      college: this.$store.state.college,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.columnId = this.$route.params.columnId;
      this.type = this.$route.params?.id;
      this.getdetail();
    },
    toList(){
      this.$router.push({
          name: "newsTrends",
          query: {
            tab: this.$route.query.tab,
            columnId: this.data.columnId,
            title:this.list[0]?.columnName
          },
        });
    },
    toDetail(item) {
      if (item.mapProperties) {
        if (item.mapProperties.erOriginLink) {
          window.open(item.mapProperties.erOriginLink);
        } else {
          const routeData = this.$router.resolve({
            name: "newsDetail",
            params: {
              columnId: this.columnId,
              id: item.id,
            },
          });
          window.open(routeData.href, "_blank");
        }
      } else {
        const routeData = this.$router.resolve({
          name: "newsDetail",
          params: {
            columnId: this.columnId,
            id: item.id,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    async getList() {
      const data = {
        pageNumber: 0,
        pageSize: 999,
        columnIds: this.columnId, //
      };
      const res = await this.API.basic.getHomeRotographList(data);

      let list = res.data.content || [];
      this.list = list;

      for (let i = 0; i < list.length; i++) {
        if (list[i].id == this.data.id) {
          this.curIndex = i;
        }
      }
    },

    async getdetail() {
      if (this.type === "list") {
        const data = {
          pageNumber: 0,
          pageSize: 10,
          columnIds: this.$route.params.columnId, //
        };
        const res = await this.API.basic.getHomeRotographList(data);
        if (res.code === "0") {
          this.data = res.data.content[0];
          this.title = res.data.content[0].title;
          this.content = res.data.content[0].content;
        }
      } else {
        const res = await this.API.basic.getManuscriptDetails(
          this.$route.params.id
        );

        if (res.code === "0") {
          this.data = res.data;
          this.title = res.data.title;
          this.content = res.data.content;
          this.getList();
        }
      }
    },
  },
  watch: {
    $route: {
      handler: function (to) {
        this.init();
        // if (this.$route.query ) {
        //   this.isHideTop = !!this.$route.query.tab
        // } else {
        //   this.isHideTop = false;
        // }
      },
    },
    '$route.query': {
      immediate: true,
      handler: function (to) {
        if (this.$route.query ) {
          console.log('----+')
          this.isHideTop = !!this.$route.query.columnId
          this.hide = !!this.$route.query.hide;
        } else {
          this.isHideTop = false;
          this.hide = false
        }
        console.log('this.isHideTop', this.isHideTop)
      },
    },
  },
  computed: {
    lastPage() {
      if (this.curIndex != 0) {
        return this.list[this.curIndex - 1];
      } else {
        return null;
      }
    },
    afterPage() {
      if (this.curIndex < this.list.length - 1) {
        return this.list[this.curIndex + 1];
      } else {
        return null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.opport {
  .card {
    position: relative;
    .opportbg {
      width: 100%;
      height: 700px;
    }
    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 500;
      font-size: 48px;
      color: #ffffff !important;
    }
  }
  .main {
    //background: #f3f3f3;
    //background: url("../assets/img/list-bg.png") no-repeat;
    background: url("../assets/img/详情页背景图.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    width: 100%;
    justify-content: center;
    .content {
      //flex: 1;
      padding: 0px 30px 0 0px;
      width: 1380px;
      //margin: 0 auto;
      .title {
        max-width: 839px;
        // height: 88px;
        font-weight: 500;
        font-size: 42px;
        color: #000000;
        line-height: 48px;
        margin-left: 250px;
      }
      .content-page {
        width: 1380px;
        background: white;
        padding-top: 50px;
        padding-left: 30px;
        padding-bottom: 50px;
      }
    }
    .some {
      width: 600px;
      padding-top: 240px;
      .xgyd {
        width: 431px;
        background: #f3f3f3;
        border-radius: 10px;
        padding: 0 0px 10px 0px;
        .title {
          height: 66px;
          background: #f3f3f3;
          font-weight: 500;
          font-size: 22px;
          color: #000000;
          display: flex;
          align-items: center;
          padding: 0 20px;
          .line {
            width: 3px;
            height: 30px;
            background: #a12916;
            margin-right: 10px;
          }
        }
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 28px;
          padding: 10px 20px;
          cursor: pointer;
          .left {
            min-width: 66px;
            height: 66px;
            border-radius: 8px;
            border: 2px solid #000000;
            opacity: 0.6;
            padding-top: 5px;
            .day {
              font-weight: 500;
              font-size: 20px;
              color: #000000;
              text-align: center;
            }
            .year {
              font-weight: 500;
              font-size: 14px;
              color: #000000;
              text-align: center;
            }
          }
          .right {
            font-weight: 400;
            font-size: 18px;
            color: #444444;
            margin-left: 16px;
          }
        }
        .item:hover {
          background: #fff;
          .left {
            border: 2px solid #c13230;

            .day {
              color: #c13230;
            }
            .year {
              color: #c13230;
            }
          }
          .right {
            color: #c13230;
          }
        }
      }
    }
  }
  .page {
    display: flex;
    //background: #fff;
    //padding-left: 380px;
    margin: 0 auto;
    align-items: center;
    //background-color: #f5f5f5;
    //padding-bottom: 40px;
    //padding-top: 40px;
    justify-content: space-between;
    width: 1380px;
    min-height: 120px;
    .list {
      //background-color: white;
      //padding-left: 30px;
    }
    .btn {
      width: 94px;
      height: 94px;
      border-radius: 47px;
      //border: 1px solid #c13230;
      background-color: #c13230;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 70px;
    }
    .item {
      font-weight: 400;
      font-size: 22px;
      color: #333333;
      opacity: 0.7;
      cursor: pointer;
      max-width: 900px;
      width: 900px;
    }
    .item:hover{
      color: #c13230;
    }
  }
}
</style>
