<template>
  <div class="text 111">
    <div class="contain">
      <div
        class="title ellipsis2"
        v-html="content.title"
      ></div>
      <div class="content_source">
        <span v-if="content.mapProperties.wenzi" >{{ $t('source_2') }}:{{ content.mapProperties.wenzi }}</span>
        <span v-if="content.mapProperties.sheying">{{ $t('source_3') }}:{{ content.mapProperties.sheying }}</span>
        <span v-if="content.author">{{ $t('source_4') }} ：{{ content.author }}</span>
        <div v-if="content.origin">来源 ：{{ content.origin || "------" }}</div>
        <div v-if="content.publishTime">
          发布时间：{{ $moment(content.publishTime).format("YYYY-MM-DD") }}
        </div>
       
<!--        <div>字号：[<span :style="fontSizeClass(1)" @click="changFontsize(1)">大</span><span :style="fontSizeClass(2)" @click="changFontsize(2)">中</span><span :style="fontSizeClass(3)" @click="changFontsize(3)">小</span>]</div>-->
      </div>
      <div class="line"></div>
      <div
        class="content 1"
        :style="`fontSize:${fontsize}px`"
        v-html="disableContentEditable(content.content)"
      ></div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      fontsize: "20",
    };
  },
  watch: {
    // content:function (vafontsize1l){
    //   val
    //   debugger
    // }
  },
  mounted() {
    if (this.content?.id) {
      this.content.primersTitle = "";
      this.content.subTitle = "";
      this.getDetails(this.content.id);
    }
  },
  created() {},
  methods: {
    fontSizeClass(type){
      if((type == 1 && this.fontsize == "24") || (type == 2 && this.fontsize == "20")|| (type == 3 && this.fontsize == "16")){
        return "color:#a12916";
      }
      return 'cursor:pointer'
    },
    changFontsize(type) {
      this.fontsize = type == 1 ? "24" : type == 2 ? "20" : "16";
    },
    async getDetails(id) {
      const res = await this.API.basic.getManuscriptDetails(id);
      this.content.primersTitle = res.data.primersTitle || "";
      this.content.subTitle = res.data.subTitle || "";
      this.$forceUpdate();
    },
    disableContentEditable(htmlString) {
      // 使用正则表达式匹配并替换 contenteditable 属性
      return htmlString.replace(/contenteditable\s*=\s*(['"])(?:(?=(\\?))\2.)*?\1/g, 'contenteditable="false"');
    }
  },
  computed:{

  }
};
</script>

<style lang="scss" scoped>
::v-deep a {
  color: #002bfd !important;
}
img {
  max-width: 100%;
}
.text {
  width: 1295px;
  .contain {
    padding: 0 110px 0 80px;
  }
  .line {
    height: 1px;
    border: 1px solid #000000;
    opacity: 0.1;
    margin-bottom: 20px;
  }
  .content_source {
    display: flex;
    font-weight: 400;
    font-size: 22px;
    color: #666666;
    // justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 10px;
    span{
      margin-right: 10px;
    }
  }
  .title {
    //font-weight: 500;
    font-size: 28px;
    color: #000000;
    text-align: center;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 43px;
    .info_left {
      height: 45px;
      font-size: 14px;
      color: #787878;
      line-height: 20px;
      padding-left: 17px;
      // border-left: 1px solid #ccc;
      span {
        margin-right: 15px;
      }
    }
    .info_right {
      .info_right_div1 {
        display: flex;
        img {
          width: 42px;
          height: 42px;
        }
        .shareImg {
          margin-right: 18px;
        }
      }
      .info_right_div2 {
        height: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-top: 10px;
        span {
          cursor: pointer;
          margin: 0 3px;
        }
        span:hover {
          color: #a12916;
        }
      }
    }
  }
  .content {
    //margin-bottom: 50px;
    font-weight: 400;
    line-height: 2em;
    color: #000 !important;
    ::v-deep img {
      margin: auto !important;
      max-width: 600px !important;
    }
    ::v-deep video {
      max-height: 500px;
    }
  }

  .nav {
    display: flex;
    height: 80px;
    border-top: 1px solid #eee;
    > div {
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 16px;
      text-align: center;
      line-height: 80px;
      cursor: pointer;
    }
    > div:hover {
      color: #a12916;
    }
    > div:first-of-type {
      border-right: 1px solid #eee;
    }
  }
}
.active1 {
  font-size: 15px;
  color: #a12916;
}
</style>
